import { createReducer } from "@reduxjs/toolkit";
import {
    setCurrentEpoch,
    setRedemptionDate,
    updateData,
    setPoolGroupsRedeem,
    setPoolGroups,
    setAddressSaffronPool, setDepositData, contractERC20s, updateDepositModalData, updateRedeemModalData, setLoading
} from "./actions";
import BigNumber from "bignumber.js";

const initialState = {
    currentEpoch: null,
    poolGroups: [],
    poolGroupsRedeem: [],
    addressSaffronPool: null,
    depositSfiBalance: null,
    dashboardData: {},
    redeemData: {},
    liquidityPoolsData: {},
    tables: {},
    redeemTabNextRemovalWindow: null,
    epochCompletedPercentage: 0,
    redeemTabNextRemovalTime: null,

    depositDaiBalance: null,
    trancheSFIMultiplierA: null,
    trancheSFIMultiplierAA: null,
    trancheSFIMultiplierS: null,
    trancheApyAA: null,
    trancheApyA: null,
    trancheApyS: null,

    depositUniV2Balance0: null,
    depositTokenABalance: null,
    depositTokenBBalance: null,

    redeemTranches: [],
    loading: false,
}

export default createReducer(initialState, builder =>
    builder
        .addCase(setLoading, (state, action) => {
            state.loading = action.payload;
        })
        .addCase(updateData, (state, action) => {
            state.depositSfiBalance = action.payload.depositSfiBalance;
            state.dashboardData = action.payload.dashboardData;
            state.redeemData = action.payload.redeemData;
            state.liquidityPoolsData = action.payload.liquidityPoolsData;
            state.tables = action.payload.tables;
        })
        .addCase(setRedemptionDate, (state, action) => {
            state.redeemTabNextRemovalTime = action.payload.redeemTabNextRemovalTime;
            state.epochCompletedPercentage = action.payload.epochCompletedPercentage;
            state.redeemTabNextRemovalWindow = action.payload.redeemTabNextRemovalWindow;
        })
        .addCase(setCurrentEpoch, (state, action) => {
            state.currentEpoch = action.payload;
        })
        .addCase(setPoolGroupsRedeem, (state, action) => {
            state.poolGroupsRedeem = action.payload;
        })
        .addCase(setPoolGroups, (state, action) => {
            state.poolGroups = action.payload;
        })
        .addCase(setAddressSaffronPool, (state, action) => {
            state.addressSaffronPool = action.payload;
        })
        .addCase(setDepositData, (state, action) => {
            state.depositSfiBalance = action.payload.depositSfiBalance;
            state.trancheSFIMultiplierA = action.payload.trancheSFIMultiplierA;
            state.trancheSFIMultiplierAA = action.payload.trancheSFIMultiplierAA;
            state.trancheSFIMultiplierS = action.payload.trancheSFIMultiplierS;
            state.trancheApyAA = action.payload.trancheApyAA;
            state.trancheApyA = action.payload.trancheApyA;
            state.trancheApyS = action.payload.trancheApyS;
        })
        .addCase(updateDepositModalData, (state, action) => {
            state.depositUniV2Balance0 = action.payload.depositUniV2Balance0;
            state.depositTokenABalance = action.payload.depositTokenABalance;
            state.depositTokenBBalance = action.payload.depositTokenBBalance;
        })
        .addCase(updateRedeemModalData, (state, action) => {
            state.redeemTranches = action.payload;
        })
)