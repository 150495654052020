import { Col } from "react-bootstrap";
import { Button } from 'antd';
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import SVG from "react-inlinesvg";

import { useWalletModalToggle } from "../../../state/application/hooks";
import Hero1 from "../../../assets/images/hero/hero.png";
import "./hero.scss";
import { useActiveWeb3React } from "../../../hooks";

const StyleButton = styled(Button)`
	height: 56px;

	&.btn-lg {
		height: 70px;
		padding: 20px 32px;
	}
`;

const Description = styled.span`
	color: ${({ theme }) => theme.text2};
	font-size: 1.125rem;
	line-height: 1.6;
	font-weight: 400;
	margin-bottom: 4rem;
`

const Hero = (props) => {
	const toggleConnectModal = useWalletModalToggle();
	const { t } = useTranslation();
	const { account } = useActiveWeb3React();

	return (
		<section className="row hero section flex">
			<Col xs={12} lg={6} className={"hero__concept order-1 lg:order-2"}>
				<a href="/#/swap/all">
				<img alt={t('app.title')} src={Hero1} style={{ width: '100%', maxWidth: '100%'}} />
				</a>
			</Col>
			<Col xs={12} lg={6} className={"flex items-center lg:items-start justify-center flex-col  text-center lg:text-left order-2 lg:order-1"}>
				<a href="/#/swap/all">
				<h1 className={"h1 mt-0 mb-1 hero__title  text-center lg:text-left"}>{t("app.description")}</h1>
				<Description>Access 36 Decentralized Exchanges in One Place </Description>
				</a><br /><br />
				<div className="items-center lg:items-start self-stretch lg:self-auto justify-center text-center lg:text-left">
					<StyleButton
						type={'primary'}
						size={'large'}
						onClick={toggleConnectModal}
						className={"w-full lg:w-auto"}
					>
						{account ? t("wallet.viewOrChange") : t("wallet.connect")}
					</StyleButton>
				</div>
			</Col>
		</section>
	);
};

export default Hero;
