import { useEffect } from "react";
import aos from "aos";

import Page from "../../components/Page";
import Hero from "./sections/Hero";
import Features from "./sections/Features";
import Currencies from "./sections/Currencies";
import Banners from "./sections/Banners";
import Banners2 from "./sections/Banners2";
import useParsedQueryString from "../../hooks/useParsedQueryString";
import { useActiveWeb3React } from "../../hooks";
import { useTranslation } from "react-i18next";
import {message} from "antd";

const HomePage = (props) => {
	const { account } = useActiveWeb3React();
	const queryString = useParsedQueryString();
	const { t } = useTranslation();

	useEffect(() => {
		aos.init();
	}, []);

	useEffect(() => {
		if (queryString && queryString.error) {
			if (account) {
				message.error(t("errors.notFound"));
			} else {
				message.error(t("errors.walletConnect"));
			}
		}
	}, [queryString]);

	return (
		<Page disableAccountCheck={true}>
			{/* Home-Hero: start */}
			<Hero />
			{/* Home-Hero: end */}		

			{/* Home-Currencies: start */}
			<Currencies />
			{/* Home-Currencies: end */}		
		</Page>
	);
};

export default HomePage;
