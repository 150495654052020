import SVG from "react-inlinesvg";
import styled from "styled-components";

import Instagram from "../../assets/images/socials/instagram.svg";
import Facebook from "../../assets/images/socials/facebook.svg";
import Linkedin from "../../assets/images/socials/linkedin.svg";
import Twitter from "../../assets/images/socials/twitter28.svg";
import Github from "../../assets/images/socials/github28.svg";
import Telegram from "../../assets/images/socials/telegram28.svg";
import Medium from "../../assets/images/socials/medium28.svg";
import "./styles.scss";

const socials = [
	{
		name: "Medium",
		image: Medium,
		url: "#",
	}, 
	{
		name: "Twitter",
		image: Twitter,
		url: "#",
	},
	{
		name: "Github",
		image: Github,
		url: "#",
	},
	{
		name: "Telegram",
		image: Telegram,
		url: "#",
	},
];

const StyledLink = styled.a`
	color: ${({ theme }) => theme.white};
`;

const Socials = (props) => {
	return (
		<div className="flex items-center justify-between">
			<ul className={"socials"}>
				{socials.map((social, i) => {
					return (
						<li className={"socials__item"} key={social.name + i}>
							<StyledLink
								href={social.url}
								target={"_blank"}
								rel={"noopener noreferrer"}
								className={"socials__link"}
							>
								<SVG src={social.image} />
							</StyledLink>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default Socials;
